
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#edit{
  color: green
}
#trash{
  color: red;
}
#view{
  color: cornflowerblue;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .active a{
  background-color:#cd0c62;
  color: #fff;
  border-radius: 10px;
} */
#sidebar{
position: fixed;
}
@media screen and (max-device-width: 425px){
  .head-section{
    margin-top: 20%;
  }
}
@media screen and (max-device-width: 320px){
  .head-section{
    margin-top: 40%;
  }
}
#errDisplay{
  color: rgb(210, 55, 55);
  font-size: 13px;
}
.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #cd1662;
  font-size: 12px;
  color: white;
  border: 1px solid #cd1662;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
.swal-overlay {
  background-color: rgba(48, 48, 48, 0.8);
}
.sand{
  color: #cd1662;
 margin: 7px;
 width: 6%;
}
@import url('https://fonts.googleapis.com/css2?family=poppins:wght@100;200;300;400;500;600&display=swap');

*{
    font-family: 'poppins', sans-serif;
    margin: 0;padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    text-decoration: none;
}

.container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 60px;
}

.container .content{
    text-align: center;
}
.container .content h3{
    font-size: 30px;
    color: #333;
}
.container .content h3 span{
    background-color: crimson;
    color: #fff;
    border-radius: 5px;
    padding: 0 15px;
}

.container .content h1{
    font-size: 50px;
    color: #333;
}

.container .content h1 span{
   color: crimson;
}

.container .content p{
    font-size: 25px;
    margin-bottom: 20px;
}

.container .content .btn{
    display: inline-block;
    font-size: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 30px;
    margin: 0px 5px;
}

.container .content .btn:hover{
    background-color: crimson;
}

.form-container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-bottom: 60px;
    background: #eee;
}

.form-container form{

    padding: 3.5%;
    border-radius: 50px;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    background: #fff;
    text-align: center;
    /* margin: 0 30%; */
    
}

.form-container form h3{

    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #333;
}

.form-container form input,
.form-container form select{
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 17px;
    margin: 8px 0;
    background: #eee;
}

.form-container form select option{
    background: #fff;
}

.form-container form .form-btn{
    background-color: crimson;
    color: #fff;
    text-transform: capitalize;
    font-size: 20px;
    cursor: pointer;
}

.form-container form .form-btn:hover{
    background: crimson;
    color: #fff;
}

.form-container form p{
    margin-top: 10px;
    font-size: 80%;
    color: #333;
}

.form-container form p a{
    color: crimson;
}

.form-container form .error-msg{
    margin: 10px 0;
    display: block;
    background: rgb(57, 141, 238);
    color: #fff;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
}



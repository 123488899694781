
.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}
.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}
.bg-lightgreen {
    background-color: #a8d7e0 !important;
} 
.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.taskboard .progress_task .dd-item:before {
    background-color: #ffc107;
}
.light-success-bg {
    background-color: #A0D9B4 !important;
}
.light-info-bg {
    background-color: #eee5ff !important;
}
.rounded-1 {
    border-radius: 0.2rem !important;
}

.taskboard .dd-item:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0px;
    bottom: 0px;
    z-index: 9;
}

.taskboard .dd-handle {
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 0px;
    padding: 20px;
    position: relative;
    color: #212529;
    border: 1px dotted #cea254;
    background-color: #ffffff;
    box-shadow: rgb(99 99 99 / 10%) 0px 2px 8px 0px;
    margin-bottom: 20px;
}
.taskboard .review_task .dd-item:before {
    background-color: #FFAA8A;
}
.taskboard .completed_task .dd-item:before {
    background-color: #28a745;
}

.wrapper-card{
    display: flex;
    flex-wrap: wrap;
  }
  .wrapper-card .card{
    width: 33.33%; 
    border: 0;
    padding: 10px;
  }
  .wrapper-card  .card-body {
    border: 1px solid #ddd;
}
.wrapper-card img{
    width: 100%;
    height: 171px;
    object-fit: cover;
}
